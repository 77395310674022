@import "CustomBootstrap";

a {
  color: $primary;
}

.px-nav {
  padding-left: 5%;
  padding-right: 5%;
}
@include media-breakpoint-up(md) {
  .px-nav {
    padding-left: 16%;
    padding-right: 16%;
  }
}

.w-nav {
  width: 12vw;
}

.w-offset-nav {
  width: 76vw;
}

.text-stroke {
  -webkit-text-stroke: 1px black;
}
.z-index-0 {
  z-index: 0;
}

@include media-breakpoint-up(md) {
  .w-md-75 {
    width: 75% !important;
  }
}

@include media-breakpoint-up(lg) {
  .w-lg-75 {
    width: 75% !important;
  }
}

.h-top-image {
  height: calc(100vw * 0.625);
}

@include media-breakpoint-up(md) {
  .visible-md {
    visibility: visible !important;
  }
}

#navigation {
  width: 12vw;
  z-index: 10000;
  a {
    color: $dark;
  }
  a.active {
    background-color: $primary;
    color: white;
  }
}

// timeline
ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid $dark;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}
